export const url = "https://messmittel-api.work.dominik-hofmann.dev/";

export const send = async (route,data={},method='POST')=>{
  let header = new Headers();
  header.append("Content-Type", "application/x-www-form-urlencoded");

  let body = new URLSearchParams();

  let keys = Object.keys(data);
  keys.forEach(key=>{
    body.append(key, data[key]);
  })

  let requestOptions = {
    method,
    header,
    body,
    redirect: 'follow'
  };

  return new Promise((resolve, reject)=>{
    fetch(url+route, requestOptions)
      .then(response => response.text())
      .then(result => resolve(JSON.parse(result)))
      .catch(error => reject(error));
  });
}

export const downloadFile = async (route,data={},method='POST')=>{
  let header = new Headers();
  header.append("Content-Type", "application/x-www-form-urlencoded");

  let body = new URLSearchParams();
  
  let keys = Object.keys(data);
  keys.forEach(key=>{
    body.append(key, data[key]);
  })

  let requestOptions = {
    method,
    header,
    body,
    redirect: 'follow'
  };

  return new Promise((resolve, reject)=>{
    fetch(url+route, requestOptions)
      .then(response => response.blob())
      .then(blob => {
        var reader = new FileReader() ;
        reader.onload = function(){ resolve(this.result)};
        reader.readAsDataURL(blob);
      })
      .catch(error => reject(error));
  });
}