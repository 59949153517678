import {useState,useEffect} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import Spinner from 'react-bootstrap/Spinner'
import Content from './Content'
import Data from './Data'
import List from './List';
import Substance from './Substance';
import Main from './Main';
import {getDbVersion} from './modules/data';

const AppRouter = ()=>{
  const [loading,setLoading] = useState(true);
  const [dbVersion,setDbVersion] = useState(false);

  const loadDbVersion = async ()=>{
    setLoading(true);
    let version = await getDbVersion();
    setDbVersion(version);
    setLoading(false);
  }

  useEffect(()=>{
    loadDbVersion();
  },[]);

  if(loading){
    return (
      <Content center>
        <div className="text-center">
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
          />
        </div>
      </Content>
    )
  }

  return (
    <Router>
      <Switch>
        <Route path="/data" exact>
          {!dbVersion?(
            <Data/>
          ):(
            <Redirect to={{pathname:'/'}} />
          )}
        </Route>
        <Route path="/app">
          {!!dbVersion?(
            <>
              <Main>
                <Route path="/app/substance/:id" exact>
                  <Substance/>
                </Route>
                <Route path="/app" exact>
                  <List/>
                </Route>
              </Main>
            </>
          ):(
            <Redirect to={{pathname:'/'}} />
          )}
        </Route>
        <Route path="/" exact>
          {!!dbVersion?(
            <Redirect to={{pathname:'/app'}} />
          ):(
            <Redirect to={{pathname:'/data'}} />
          )}
        </Route>
        <Route path="*">
          <Redirect to={{pathname:'/'}} />
        </Route>
      </Switch>
    </Router>
  );
}

export default AppRouter;
