import {useState,useRef} from 'react';

import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Spinner from 'react-bootstrap/Spinner'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

import { LinkContainer } from 'react-router-bootstrap'

import { download,sendFileToBrowser } from './modules/data';

const Main = ({children})=>{
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loading,setLoading] = useState(false);

  const password = useRef(null);

  const downloadData = async ()=>{
    let pw = password.current.value;
    if(!pw){return;}
    setLoading(true);

    const data = await download(pw);
    if(data){
      handleClose();
      window.location.reload();
    }
    setLoading(false);
  }

  const downloadExport = ()=>{
    sendFileToBrowser('export');
  }

  return (
    <>
      <Navbar bg="primary" variant="dark" expand="lg">
        <LinkContainer to="/app">
          <Navbar.Brand>Gefahrgut - MSP</Navbar.Brand>
        </LinkContainer>
        <div className="ms-auto">
          <Button variant={'secondary'} size={"sm"} className="download-icon" onClick={handleShow}>&nbsp;</Button>
        </div>
      </Navbar>
      {children}
      <Offcanvas show={show} onHide={handleClose} placement={'end'}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Daten aktuallisieren</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!!window.navigator.onLine && (
            <div>
              Sie können die Daten nun aktuallisieren:
              {loading&&(
                <div className="text-center mt-4">
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              )}
              {!loading&&(
                <div className="mt-4">
                  <InputGroup>
                    <Form.Control type="password" placeholder="Password" className="mr-1" ref={password} />
                    <Button onClick={()=>{downloadData()}}>Herunterladen</Button>
                  </InputGroup>
                </div>
              )}
            </div>
          )}
          {!window.navigator.onLine && (
            <div>Sie können die Daten nur aktuallisieren, wenn Sie mit dem Internet verbunden sind.</div>
          )}
          <div className="mt-4">
            <Button onClick={()=>{downloadExport()}}>XLSX herunterladen</Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Main;
