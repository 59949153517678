import {useState,useEffect} from 'react';

import {
  useParams
} from "react-router-dom";

import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import ListGroup from 'react-bootstrap/ListGroup'

import { LinkContainer } from 'react-router-bootstrap'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import {getData,sendFileToBrowser} from './modules/data'

import Content from './Content'

const Substance = ()=>{
  let { id } = useParams();
  const [loading,setLoading] = useState(false);
  const [data,setData] = useState(false);
  const [equipment,setEquipment] = useState([]);

  const loadData = async ()=>{
    setLoading(true);
    const substance = await getData('substance',{id});
    setData(substance[0]);
    let equipments = [];
    const substance_equipment = await getData('substance_equipment',{substance_id:id});
    for(let i=0;i<substance_equipment.length;i++){
      let equipment = await getData('equipment',{id:substance_equipment[i].equipment_id});
      equipment[0].exact = substance_equipment[i].exact;
      equipment[0].area_from = substance_equipment[i].area_from;
      equipment[0].area_to = substance_equipment[i].area_to;
      let brand = await getData('brand',{id:equipment[0].brand_id});
      equipment[0].brand = brand[0].name;
      equipment[0].vehicle = [];
      let vehicle_equipment = await getData('vehicle_equipment',{equipment_id:equipment[0].id});
      for(let c=0;c<vehicle_equipment.length;c++){
        let vehicle = await getData('vehicle',{id:vehicle_equipment[c].vehicle_id});
        vehicle[0].qty = vehicle_equipment[c].qty;
        equipment[0].vehicle.push(vehicle[0]);
      }
      equipments.push(equipment[0]);
    }
    setEquipment(equipments.sort((a, b)=>{
      if (a.exact > b.exact) {
        return -1;
      }
      if (a.exact < b.exact) {
        return 1;
      }
      return 0;
    }));
    setLoading(false);
  }

  useEffect(()=>{
    loadData();
  },[id]);

  const numberFormat = (n)=>{
    return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2}).format(n)
  }

  return (
    <>
      <Content>
        {loading && (
          <div className="text-center mt-5">
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
          </div>
        )}
        {!loading && !!data && (
          <Row>
            <Col>
              <Card md={6} lg={5} className="mb-3">
                <Card.Header>{data.name}</Card.Header>
                <ListGroup variant="flush">
                  {(!!data.formula || !!data.un || !!data.cas) && (
                    <ListGroup.Item className="text-center">
                      {!!data.un &&(<Badge className="mx-1" text="dark" bg="warning">UN {data.un}</Badge>)}
                      {!!data.cas &&(<Badge className="mx-1" text="dark" bg="info">CAS {data.cas}</Badge>)}
                      {!!data.formula &&(<Badge bg="success" className="mx-1" dangerouslySetInnerHTML={{__html:data.formula.replace(/(\d)/g,"<sub>$1</sub>")}} />)}
                    </ListGroup.Item>
                  )}
                  {!!data.etw_1 &&(
                    <ListGroup.Item>
                      <div className="d-flex justify-content-between"><div>ETW-1:</div><div>{data.etw_1}</div></div>
                    </ListGroup.Item>
                  )}
                  {!!data.etw_4 &&(
                    <ListGroup.Item>
                      <div className="d-flex justify-content-between"><div>ETW-4:</div><div>{data.etw_4}</div></div>
                    </ListGroup.Item>
                  )}
                  {!!data.mak &&(
                    <ListGroup.Item>
                      <div className="d-flex justify-content-between"><div>MAK-Wert:</div><div>{data.mak}</div></div>
                    </ListGroup.Item>
                  )}
                  {!!data.ueg_100 &&(
                    <ListGroup.Item>
                      <div className="d-flex justify-content-between"><div>UEG:</div><div>{data.ueg_100}</div></div>
                    </ListGroup.Item>
                  )}
                  {!!data.oeg_100 &&(
                    <ListGroup.Item>
                      <div className="d-flex justify-content-between"><div>OEG:</div><div>{data.oeg_100}</div></div>
                    </ListGroup.Item>
                  )}
                  {!!data.mol &&(
                    <ListGroup.Item>
                      <div className="d-flex justify-content-between"><div>Molare Masse:</div><div>{numberFormat(data.mol)} g/mol <LuftArrow value={data.mol}/></div></div>
                    </ListGroup.Item>
                  )}
                  {!!data.wiki && (
                    <ListGroup.Item>
                      <Button onClick={()=>window.open(data.wiki)} variant={"primary"}>ERICard</Button>
                    </ListGroup.Item>
                  )}
                </ListGroup>
                {!!data.alternative_names && (
                  <Card.Body>
                    <small>
                      {data.alternative_names.split(/\s*,\s+/).map((i,k)=>(<div key={k}>{i}</div>))}
                    </small>
                  </Card.Body>
                )}
              </Card>
              <div className="text-center text-md-start">
                <LinkContainer to="/app" className="mb-3">
                  <Button variant="secondary">Zurück</Button>
                </LinkContainer>
                <Button variant="primary" className="mb-3 ms-1" onClick={window.print}>Drucken</Button>
              </div>
            </Col>
            <Col md={6} lg={7}>
              <Equipment data={equipment}/>
            </Col>
          </Row>
        )}
      </Content>
    </>
  );
}

const LuftArrow = ({value})=>{
  const luft = 28.949;
  if(value > luft +1){
    return (<Badge className="ml-2" title="Schwerer als Luft" variant="light">↓</Badge>);
  }else if(value < luft -1){
    return (<Badge className="ml-2" title="Leichter als Luft" variant="light">↑</Badge>);
  }else{
    return (<Badge className="ml-2" title="Ähnlich wie Luft" variant="light">⊛</Badge>);
  }
}

const Equipment = ({data})=>{
  return (
    <>
      {data.map((v,k)=>(
        <Card className={"mb-2"} key={"eq_"+v.id}>
          <Card.Header>
            <div className="d-flex justify-content-between">
              <div>{v.name}</div>
              <div>
                {v.exact !== '1' && (<><Badge className="ml-1" bg={"danger"}>Nachweis</Badge><span> </span></>)}
                {!!v.brand_nr && (<Badge className="ml-1" bg={"light"} text="dark">{v.brand_nr}</Badge>)}
              </div>
            </div>
          </Card.Header>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <div className="d-flex justify-content-between"><div>Hersteller:</div><span>{v.brand}</span></div>
            </ListGroup.Item>
            {v.exact === '1' && v.area_from && (
              <ListGroup.Item>
                <div className="d-flex justify-content-between"><div>Messbereich von:</div><span>{v.area_from}</span></div>
              </ListGroup.Item>
            )}
            {v.exact === '1' && v.area_to && (
              <ListGroup.Item>
                <div className="d-flex justify-content-between"><div>Messbereich bis:</div><span>{v.area_to}</span></div>
              </ListGroup.Item>
            )}
            {!!v.vehicle && (
              <ListGroup.Item>
                <div>Verfügbarkeit:</div>
                <small>
                  {v.vehicle.map((v,k)=>(
                    <div key={'veh_'+k}>
                      <div className="d-flex justify-content-between"><div><strong>{v.name}</strong> ({v.radio}):</div><span>{v.qty}x</span></div>
                    </div>
                  ))}
                </small>
              </ListGroup.Item>
            )}

            {v.info && (
              <ListGroup.Item>
                <Button onClick={()=>sendFileToBrowser(v.info)} size="sm">Begleitkarte</Button>
              </ListGroup.Item>
            )}
          </ListGroup>
        </Card>
      ))}
    </>
  )
}

export default Substance;
