import {send,downloadFile} from './api.js';
import { saveAs } from 'file-saver';

const dbName = "gefahrgut";

export const support = !!window.indexedDB;

export const getDbVersion = async ()=>{
  const databases = await window.indexedDB.databases();
  let version = false;
  for(let i=0;i<databases.length;i++){
    if(databases[i].name === dbName){
      version = databases[i].version;
    }
  }
  return version
}

export const download = async (password)=>{
  const data      = await send('database',{auth:password});

  if(data?.error){
    return false;
  }

  const dbData = data.content;

  // const dbVersion = await getDbVersion();
  // if(dbData.version === dbVersion){
  //   return true;
  // }

  return new Promise((resolve, reject)=>{
    var db;
    var request = indexedDB.open(dbName,dbData.version);
    var files = [];

    const downloadFiles = async (files)=>{
      for(let i=0;i<files.length;i++){

        var file =  await downloadFile(files[i]==='export'?'export':'files/'+files[i],{auth:password});
        let transaction = db.transaction(["files"], "readwrite");
        let store = transaction.objectStore("files");
        store.add({
          name: files[i],
          file:file
        });
      }
    }

    request.onerror = function(event) {
      reject(false);
    };
    request.onsuccess = async function(event) {
      db = request.result;

      await downloadFiles(files,db);
      resolve(db);
    };
    request.onupgradeneeded = async function(event) {
      var db = event.target.result;

      try{
        db.deleteObjectStore('files');
      }catch(e){}

      for(let i=0;i<dbData.tables.length;i++){
        try{
          db.deleteObjectStore(dbData.tables[i].name);
        }catch(e){}
        var objectStore = db.createObjectStore(dbData.tables[i].name, { keyPath: dbData.tables[i].key });
        let keys = Object.keys(dbData.tables[i].index);
        for(let c=0;c<keys.length;c++){
          objectStore.createIndex(keys[c], keys[c], { unique: dbData.tables[i].index[keys[c]] });
        }

        for(let d=0;d<dbData.tables[i].data.length;d++){
          var req = objectStore.add(dbData.tables[i].data[d]);
          if(dbData.tables[i].data[d].info){
            files.push(dbData.tables[i].data[d].info);
          }
          req.onerror = function(event) {
            reject(false);
          };
        }
      }
      files = files.filter((value, index, self) => {
        return self.indexOf(value) === index
      });

      files.push('export');

      db.createObjectStore('files', { keyPath: 'name' });
    };
  });
}

const getDb = async ()=>{
  return new Promise(async(resolve, reject)=>{
    const request = indexedDB.open(dbName,await getDbVersion());
    request.onsuccess = function(event) {
      resolve(event.target.result);
      
    };
    request.onerror = function(event) {
      reject(false);
    };
  });
  
  
}

export const getData = async (object,filter={})=>{
  const db = await getDb();
  const transaction = db.transaction(object,'readonly');
  const store = transaction.objectStore(object);

  

  return new Promise(async(resolve)=>{
    const allRecords = store.getAll();
    allRecords.onsuccess = function() {
      let filter_keys = Object.keys(filter);
      if(filter_keys.length){
        resolve(allRecords.result.filter(v=>{
          var keep = true;
          for(let i=0;i<filter_keys.length;i++){
            if(v[filter_keys[i]] != filter[filter_keys[i]]){
              keep = false;
            }
          }
          return keep;
        }));
      }else{
        resolve(allRecords.result);
      }
      
    };
  });
}

const convertBase64ToFile = (base64String, fileName) => {
    let arr = base64String.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let uint8Array = new Uint8Array(n);
    while (n--) {
      uint8Array[n] = bstr.charCodeAt(n);
    }
    let file = new File([uint8Array], fileName, { type: mime });
    return file;
}

export const sendFileToBrowser = async (file)=>{
  const db = await getDb();
  const transaction = db.transaction('files','readonly');
  const store = transaction.objectStore('files');
  const fileData = store.get(file);
  fileData.onsuccess = function() {
    const linkSource = fileData.result.file;
    const fileName = file==='export'?'export.xlsx':file;


    let dlFile = convertBase64ToFile(linkSource, fileName);
    saveAs(dlFile, fileName);
  }
}