import {useState,useEffect} from 'react';

import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'
import Badge from 'react-bootstrap/Badge'
import ListGroup from 'react-bootstrap/ListGroup'

import { LinkContainer } from 'react-router-bootstrap'

import {getData} from './modules/data'

import Content from './Content'

const List = ()=>{
  const [loading,setLoading] = useState(false);
  const [data,setData] = useState(false);
  const [fragment,setFragment] = useState("");

  const loadList = async ()=>{
    setLoading(true);
    let substances = await getData('substance');
    setData(substances.sort((a, b)=>{
      var nameA = a.name.toUpperCase();
      var nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    }));
    setLoading(false);
  }

  useEffect(()=>{
    loadList();
  },[])

  const changeText = (e)=>{
    const t = e.target.value;
    setFragment(t);
  }


  return (
    <Content>
      <div className="sticky-search">
        <Form className="mb-3" onSubmit={e=>e.preventDefault()}>
          <FormControl type="text" placeholder="Suche…" onChange={changeText}/>
        </Form>
      </div>
      {loading && (
        <div className="text-center mt-5">
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
          />
        </div>
      )}
      {!loading && !!data && (
        <>
          {!!data.length && (
            <ListGroup className={"mb-3"}>
              {data.filter(i=>{
                if(!fragment.length){return true;}
                if(
                  i.name.toUpperCase().indexOf(fragment.toUpperCase()) >= 0 ||
                  (i.alternative_names && i.alternative_names.toUpperCase().indexOf(fragment.toUpperCase()) >= 0 ) || 
                  (i.cas && i.cas.toUpperCase().indexOf(fragment.toUpperCase()) >= 0) ||
                  (i.un && i.un.toUpperCase().indexOf(fragment.toUpperCase()) >= 0) ||
                  (i.formula && i.formula.toUpperCase().indexOf(fragment.toUpperCase()) >= 0)
                ){
                  return true;
                }
                return false;
              }).map((v)=>(
                <LinkContainer to={"/app/substance/"+v.id} key={v.id}>
                  <ListGroup.Item action>
                    {v.name}
                    <div>
                      {!!v.un&&(<><Badge bg="warning" text="dark">UN {v.un}</Badge>{' '}</>)}
                      {!!v.cas&&(<><Badge bg="info" text="dark">CAS {v.cas}</Badge>{' '}</>)}
                      {!!v.formula&&(<><Badge bg="success" dangerouslySetInnerHTML={{__html:v.formula.replace(/(\d)/g,"<sub>$1</sub>")}}></Badge>{' '}</>)}
                    </div>
                  </ListGroup.Item>
                </LinkContainer>
              ))}
            </ListGroup>
          )}
          {!data.length && (
            <Alert variant={"dark"} className="mt-5">Es konnten keine Ergebnisse gefunden werden.</Alert>
          )}
        </>
      )}
      {!loading && !data && (
        <Alert variant={"danger"} className="mt-5">Es ist ein Fehler aufgetreten. Laden Sie die Seite neu und versuchen Sie es noch einmal.</Alert>
      )}

    </Content>
  );
}

export default List;
