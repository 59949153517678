import '../assets/css/content.scss';

const Content = ({children,small=false,center=false})=>{
  return (
    <div className={"content"+(small?" content--small":"")+(center?" content--center":"")}>
      <div className="content__inner">
        {children}
      </div>
    </div>
  )
}

export default Content;
