import {useState,useRef} from 'react';

import {
  Redirect
} from "react-router-dom";

import Content from './Content';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

import { support,download } from './modules/data';


const Data = ()=>{
  const [loading,setLoading] = useState(false);
  const [ready,setReady] = useState(false);

  const password = useRef(null);

  const downloadData = async ()=>{
    let pw = password.current.value;
    if(!pw){return;}
    setLoading(true);

    const data = await download(pw);
    if(data){
      setReady(true);
      window.location.reload();
    }
    setLoading(false);
  }

  if(ready){
    return (<Redirect to={{pathname:'/'}} />)
  }

  return (
    <Content small center>
      <Card>
        <Card.Header>Gefahrgut-Daten herunterladen</Card.Header>
        {!support?(
          <Card.Body>Ihr Browser unterstützt diese Technologie nicht!</Card.Body>
        ):(
          <Card.Body>
            Es wurden keine Gefahrgut-Daten in Ihrem Browser erkannt.<br/>Laden Sie die Daten jetzt herunter, um sie auch offline zu nutzen.
            {loading&&(
              <div className="text-center mt-4">
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                />
              </div>
            )}
            {!loading&&(
              <div className="mt-4">
                <InputGroup>
                  <Form.Control type="password" placeholder="Password" className="mr-1" ref={password} />
                  <Button onClick={()=>{downloadData()}}>Herunterladen</Button>
                </InputGroup>
              </div>
            )}
          </Card.Body>
        )}
        
      </Card>
    </Content>
  )
}

export default Data;


